import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Galery from "./pages/Galery/Galery";
import Home from "./pages/Home/Home";
import Layout from "./pages/Layout/Layout";

const routesTSX = (
  <Route path="/" element={<Layout />}>
    <Route path="" element={<Home />} />
    <Route path="/galery/:type" element={<Galery />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
  </Route>
);

const routes = createRoutesFromElements(routesTSX);

export const router = createBrowserRouter(routes);
