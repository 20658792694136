import Envelope from "../../assets/envelope-30.png";
import Instagram from "../../assets/instagram-50.png";
import Marker from "../../assets/map-marker-50.png";
import Phone from "../../assets/phone-30.png";
import "./Contact.css";
import "../../assets/css/main.css";

const Contact = () => {
  return (
    <div className="grid-contact">
      <h2>Kontaktirajte nas:</h2>

      <div className="contact-vertical">
        <div className="contact-horizontal">
          <p>Požega,&nbsp;Gorobilje&nbsp;- Svilaići 7,&nbsp; Srbija</p>
          <img src={Marker} alt="marker" />
        </div>
        <div className="contact-horizontal">
          <p>+381&nbsp;65&nbsp;3334427</p>
          <img src={Phone} alt="phone" />
        </div>
        <div className="contact-horizontal">
          <p>stulicenterijeri@gmail.com</p>
          <img src={Envelope} alt="envelope" />
        </div>
        <div className="contact-horizontal">
          <p>@stulic_enterijeri</p>
          <img src={Instagram} alt="instagram" />
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11516.433972008517!2d20.08951233916861!3d43.81210894919281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4757797b4183ba1d%3A0x663cc6b54a11434!2zxaB0dWxpxIcgRW50ZXJpamVyaQ!5e0!3m2!1ssr!2srs!4v1696680452699!5m2!1ssr!2srs"
          title="lokacija"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <footer id="footer">
        <ul className="icons">
          {/* <li>
            <a href="#" className="icon brands alt fa-twitter">
              <span className="label">Twitter</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61551257108922"
              className="icon brands alt fa-facebook-f"
            >
              <span className="label">Facebook</span>
            </a>
          </li>
          {/* <li>
            <a href="#" className="icon brands alt fa-linkedin-in">
              <span className="label">LinkedIn</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/stulic_enterijeri/"
              className="icon brands alt fa-instagram"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          {/* <li>
            <a href="#" className="icon brands alt fa-github">
              <span className="label">GitHub</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://mail.google.com/"
              className="icon solid alt fa-envelope"
            >
              <span className="label">Email</span>
            </a>
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy;All rights reserved.</li>
          <li>
            Design:{" "}
            <a href="https://www.linkedin.com/in/branimir-mari%C4%8Di%C4%87-16769a165/">
              {" "}
              BANE
            </a>
          </li>
        </ul>
      </footer>
      </div>
      
    </div>
  );
};

export default Contact;
