import ElseImg2 from "../../assets/real/20230727_183000.jpg";
import ElseImg1 from "../../assets/real/20230803_172725.jpg";
import ElseImg3 from "../../assets/real/20230809_194519.jpg";
import ElseImg4 from "../../assets/real/20230810_092116.jpg";
import ElseImg5 from "../../assets/real/20230811_170138.jpg";
import ElseImg7 from "../../assets/real/20230813_140823.jpg";
import KitchenImg2 from "../../assets/real/kitchens/20230721_190949.jpg";
import KitchenImg1 from "../../assets/real/kitchens/20230721_191023.jpg";
import KitchenImg3 from "../../assets/real/kitchens/20230727_182942.jpg";
import KitchenImg4 from "../../assets/real/kitchens/20230727_183132.jpg";
import KitchenImg5 from "../../assets/real/kitchens/20230803_172715.jpg";
import KitchenImg6 from "../../assets/real/kitchens/20230809_194504.jpg";
import KitchenImg7 from "../../assets/real/kitchens/20230809_194538.jpg";
import KitchenImg8 from "../../assets/real/kitchens/20230810_182244.jpg";
import KitchenImg9 from "../../assets/real/kitchens/20230811_170039.jpg";
import ElseImg9 from "../../assets/real/result (3).png";

import BedImg2 from "../../assets/real/bedrooms/result (1).png";
import BedImg6 from "../../assets/real/bedrooms/result (2).png";
import BedImg1 from "../../assets/real/bedrooms/result.png";

import SlideImage1 from "../../assets/real/slideImage1.jpeg";
import SlideImage2 from "../../assets/real/slideImage2.jpeg";
import SlideImage3 from "../../assets/real/slideImage3.jpeg";

import { useState } from "react";
import { useLocation } from "react-router-dom";
import ImageModal from "../../components/ImageModal";
import "./Galery.css";
import BottomNavbar from "../../Navbar/BottomNavbar";

const Galery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string>("");
  const location = useLocation();
  const currentPath = location.pathname;
  const openModal = (image: string) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="grid-galery">
      {(currentPath.includes("other") || currentPath.includes("all")) && (
        <>
          <div onClick={() => openModal(ElseImg1)} className="galery-item">
            <img src={ElseImg1} alt="" />
            {/* <p>This is the description for Image 1.</p> */}
          </div>

          <div onClick={() => openModal(SlideImage2)} className="galery-item">
            <img src={SlideImage2} alt="" />
          </div>
          <div onClick={() => openModal(ElseImg2)} className="galery-item">
            <img src={ElseImg2} alt="" />
            {/* <p>This is the description for Image 2.</p> */}
          </div>
          <div onClick={() => openModal(ElseImg3)} className="galery-item">
            <img src={ElseImg3} alt="" />
            {/* <p>This is the description for Image 3.</p> */}
          </div>
          <div onClick={() => openModal(ElseImg4)} className="galery-item">
            <img src={ElseImg4} alt="" />
            {/* <p>This is the description for Image 4.</p> */}
          </div>
          <div onClick={() => openModal(ElseImg5)} className="galery-item">
            <img src={ElseImg5} alt="" />
            {/* <p>This is the description for Image 5.</p> */}
          </div>

          <div onClick={() => openModal(SlideImage1)} className="galery-item">
            <img src={SlideImage1} alt="" />
          </div>
          <div onClick={() => openModal(ElseImg7)} className="galery-item">
            <img src={ElseImg7} alt="" />
            {/* <p>This is the description for Image 3.</p> */}
          </div>
          <div onClick={() => openModal(ElseImg9)} className="galery-item">
            <img src={ElseImg9} alt="" />
            {/* <p>This is the description for Image 5.</p> */}
          </div>

          <div onClick={() => openModal(SlideImage3)} className="galery-item">
            <img src={SlideImage3} alt="" />
          </div>
        </>
      )}
      {(currentPath.includes("kitchens") || currentPath.includes("all")) && (
        <>
          <div onClick={() => openModal(KitchenImg1)} className="galery-item">
            <img src={KitchenImg1} alt="" />
            {/* <p>This is the description for Image 3.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg2)} className="galery-item">
            <img src={KitchenImg2} alt="" />
            {/* <p>This is the description for Image 4.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg3)} className="galery-item">
            <img src={KitchenImg3} alt="" />
            {/* <p>This is the description for Image 5.</p> */}
          </div>

          <div onClick={() => openModal(KitchenImg4)} className="galery-item">
            <img src={KitchenImg4} alt="" />
            {/* <p>This is the description for Image 3.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg5)} className="galery-item">
            <img src={KitchenImg5} alt="" />
            {/* <p>This is the description for Image 4.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg6)} className="galery-item">
            <img src={KitchenImg6} alt="" />
            {/* <p>This is the description for Image 5.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg7)} className="galery-item">
            <img src={KitchenImg7} alt="" />
            {/* <p>This is the description for Image 3.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg8)} className="galery-item">
            <img src={KitchenImg8} alt="" />
            {/* <p>This is the description for Image 4.</p> */}
          </div>
          <div onClick={() => openModal(KitchenImg9)} className="galery-item">
            <img src={KitchenImg9} alt="" />
            {/* <p>This is the description for Image 5.</p> */}
          </div>
        </>
      )}
      {(currentPath.includes("bedrooms") || currentPath.includes("all")) && (
        <>
          <div onClick={() => openModal(BedImg1)} className="galery-item">
            <img src={BedImg1} alt="" />
            {/* <p>This is the description for Image 1.</p> */}
          </div>
          <div onClick={() => openModal(BedImg2)} className="galery-item">
            <img src={BedImg2} alt="" />
            {/* <p>This is the description for Image 2.</p> */}
          </div>

          <div onClick={() => openModal(BedImg6)} className="galery-item">
            <img src={BedImg6} alt="" />
            {/* <p>This is the description for Image 6.</p> */}
          </div>
        </>
      )}
      <ImageModal
        image={selectedImage}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <BottomNavbar />
    </div>
  );
};

export default Galery;
