import CarpentryImg from "../../assets/carpenter.jpeg";
import Furniture1 from "../../assets/image_1.jpg";
import CrazyChair from "../../assets/crazy-chair2.jpg";
import { router } from "../../Routes";
import "./About.css";

const About = () => {
  return (
    <div className="grid-about">
      <div className="about-item">
        <h3>Enterijeri podržani dugogodišnjim iskustvom.</h3>
        <br />
        <br />
        <br />
      </div>
      <div className="about-item">
        <img src={CarpentryImg} alt="Our Team" />
      </div>
      <p>
        Naša priča počinje sa svakim rezom drveta, svakim detaljem koji
        dodajemo, i svakim komadom nameštaja koji donosimo u vaš dom.
      </p>
      <div className="about-item">
        <img src={Furniture1} alt="Our Team" />
      </div>
      <p>
        Sa strašću prema obradi drveta i ljubavlju prema dizajnu, stvaramo
        unikatne komade nameštaja koji oslikavaju toplinu i eleganciju.
      </p>
      <div className="about-item">
        <img src={CrazyChair} alt="Our Office" />
      </div>
      <p>
        Dobrodošli u našu radionicu, gde se drvce pretvara u umetnost, a vaši
        domovi postaju priče koje se pamte.{" "}
      </p>
      <button onClick={() => router.navigate("/contact")}>
        Kontaktirajte nas.
      </button>
    </div>
  );
};

export default About;
