import { router } from "../../Routes";
import "../../assets/css/main.css";
import "../../assets/css/noscript.css";
import "./Home.css";

const Home = () => {
  return (
    <div>
      <section id="banner">
        <div className="content">
          <header>
            <h2>Nameštaj je ovde.</h2>
            <p>
              Gde vaš dom iz snova postaje stvarnost.
              <br />
              Kreirajte svoj prostor. Bez ograničenja.
            </p>
          </header>
        </div>
        <a href="#one" className="goto-next scrolly">
          Next
        </a>
      </section>

      <section id="one" className="spotlight style1 bottom">
        <span className="image fit main"></span>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-4 col-12-medium">
                <header>
                  <h2>Oblikovanje Kuhinja Koje Inspirišu</h2>
                  <p>Gde Kulinarstvo Sreće Estetski Sjaj</p>
                </header>
              </div>
              <div className="col-4 col-12-medium">
                <p>
                  Srce svakog doma, kuhinja, zaslužuje ništa manje od
                  savršenstva. Naša radionica specijalizuje se za izradu kuhinja
                  koje spajaju funkcionalnost sa elegancijom.
                </p>
              </div>
              <div className="col-4 col-12-medium">
                <p>
                  Od prilagođenih ormara koji maksimizuju prostor za odlaganje
                  do radnih površina koje zrače lepotom, naši dizajni kuhinja su
                  svedočanstvo naše posvećenosti izvrsnosti.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="#two" className="goto-next scrolly">
          Next
        </a>
      </section>

      <section id="two" className="spotlight style2 right">
        <span className="image fit main"></span>
        <div className="content">
          <header>
            <h2>Spavaće Sobe</h2>
            <p>Odmor za Dušu</p>
          </header>
          <p>
            Vaša spavaća soba treba biti više od mesta za spavanje; trebala bi
            biti oaza gde možete pobeći od sveta i obnoviti se. U našoj
            radionici verujemo u moć spavaćih soba koje stvaraju atmosferu mira
            i udobnosti. Od ručno izrađenih uzglavlja do jedinstvenih rešenja za
            osvetljenje, mi svaki kutak obogaćujemo kreativnošću i udobnošću.
          </p>
          <ul className="actions">
            <li>
              <div
                onClick={() => router.navigate("/galery/bedrooms")}
                className="button"
              >
                Galerija
              </div>
            </li>
          </ul>
        </div>
        <a href="#three" className="goto-next scrolly">
          Next
        </a>
      </section>

      <section id="three" className="spotlight style3 left">
        <span className="image fit main bottom"></span>
        <div className="content">
          <header>
            <h2>Podignite Vaše Prostorne Površine na Viši Nivo</h2>
            <p>Gde Se Umetnost Susreće s Funkcionalnošću</p>
          </header>
          <p>
            Naša radionica prihvata izazov pretvaranja vaših životnih prostora u
            umetnička dela. Od prilagođenih komada nameštaja koji pričaju priču
            do inovativnih rešenja za odlaganje, mi podižemo vaše enterijere na
            nove visine.
          </p>
          <ul className="actions">
            <li>
              <div onClick={() => router.navigate("/about")} className="button">
                Saznaj više
              </div>
            </li>
          </ul>
        </div>
        <a href="#four" className="goto-next scrolly">
          Next
        </a>
      </section>

      {/* Savet stručnjaka iz firme ŠTULIĆ ENTERIJERI

1. Kada govorimo o odluci angažovanja stručnjaka za izradu nameštaja po meri, o kojim stvarima klijenti u startu treba da razmišljaju kako bi ceo proces dogovora i izrade olakšali prvo Vama a kasnije i sebi?

Prva i osnovna stvar koja meni i mom timu mnogo olakšava posao je kada klijent zna koji mu je budžet i približno šta želi. Kada imamo ove dve informacije, lakše nam je da damo svoje predloge i savete. Naša firma nudi usluge 3D projektovanja, tako da ukoliko klijenti ima primer onoga što želi, kako želi i slično, tu smo da na licu mesta njihovo želje realizuje i damo kvalutetne savete. 

2. Šta je trenutno najpopularnije kada govorimo o izradi nameštaja po meri? (boje, materijali, stilovi)

Još uvek su najpopularnije neutralne boje i minimalizam. Što se materijala tiče, drvo je vanvremenski materijal čija popularnost nikad ne jenjava, mada tu su i medijapan u kombinaciji sa drugim materijalima. 

3. Kakva je trenutna situacija na tržištu što se tiče cena materijala? Koja vrsta materijala opravdava cenu i kvalitet?

Cene materijala su definitivno otišle gore, što se tiče finalnog proizvoda, cena zavisi od dosta faktora. Svaki  materijal ima svoje prednosti i mane, sve zavisi od toga šta vi kao klijent želite. Sa pravim majstorima, svaki materijal će opravdati svoju cenu i kvalitet. 

Mladen Štulić, ŠTULIĆ ENTERIJERI */}
      <section id="magazine-feature" className="wrapper style1 special fade-up">
        <div className="container">
          <header className="major">
            <h2>Savet stručnjaka iz firme ŠTULIĆ ENTERIJERI</h2>
            <h4>
              Mladen Štulić za{" "}
              <a href="https://www.daibau.rs/clanak/148/kuhinjski_pultovi_cena_i_izbor#stulic-enterijeri">
                daibau.rs
              </a>
            </h4>
          </header>
          <div className="box alt">
            <div className="row gtr-uniform">
              <section className="col-12">
                <h3>
                  1. Kada govorimo o odluci angažovanja stručnjaka za izradu
                  nameštaja po meri, o kojim stvarima klijenti u startu treba da
                  razmišljaju kako bi ceo proces dogovora i izrade olakšali prvo
                  Vama a kasnije i sebi?
                </h3>
                <p>
                  Prva i osnovna stvar koja meni i mom timu mnogo olakšava posao
                  je kada klijent zna koji mu je budžet i približno šta želi.
                  Kada imamo ove dve informacije, lakše nam je da damo svoje
                  predloge i savete. Naša firma nudi usluge 3D projektovanja,
                  tako da ukoliko klijent ima primer onoga što želi, kako želi i
                  slično, tu smo da na licu mesta njihove želje realizujemo i
                  damo kvalitetne savete.
                </p>
              </section>
              <section className="col-12">
                <h3>
                  2. Šta je trenutno najpopularnije kada govorimo o izradi
                  nameštaja po meri? (boje, materijali, stilovi)
                </h3>
                <p>
                  Još uvek su najpopularnije neutralne boje i minimalizam. Što
                  se materijala tiče, drvo je vanvremenski materijal čija
                  popularnost nikad ne jenjava, mada tu su i medijapan u
                  kombinaciji sa drugim materijalima.
                </p>
              </section>
              <section className="col-12">
                <h3>
                  3. Kakva je trenutna situacija na tržištu što se tiče cena
                  materijala? Koja vrsta materijala opravdava cenu i kvalitet?
                </h3>
                <p>
                  Cene materijala su definitivno otišle gore, što se tiče
                  finalnog proizvoda, cena zavisi od dosta faktora. Svaki
                  materijal ima svoje prednosti i mane, sve zavisi od toga šta
                  vi kao klijent želite. Sa pravim majstorima, svaki materijal
                  će opravdati svoju cenu i kvalitet.
                </p>
                <h4>Mladen Štulić, ŠTULIĆ ENTERIJERI</h4>
              </section>
            </div>
          </div>
          <footer className="major">
            <ul className="actions special">
              <li>
                <a
                  href="https://www.daibau.rs/clanak/148/kuhinjski_pultovi_cena_i_izbor#stulic-enterijeri"
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                >
                  Pročitajte više
                </a>
              </li>
            </ul>
          </footer>
        </div>
      </section>

      <section id="four" className="wrapper style1 special fade-up">
        <div className="container">
          <header className="major">
            <h2>Individualni Dizajn i Kreacija Nameštaja po Meri</h2>
            <p>Savremeni Dizajn i Usluge Opremanja Enterijera</p>
          </header>
          <div className="box alt">
            <div className="row gtr-uniform">
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-chart-area"></span>
                <h3>3D projektovanje i izrada nameštaja po meri</h3>
              </section>
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-chair"></span>
                <h3>Pločasti i tapacirani nameštaj</h3>
              </section>
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-tree"></span>
                <h3>Moderni dezeni iverice visokog kvaliteta</h3>
              </section>
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-palette"></span>
                <h3>Farbani MDF - veliki izbor štof karti</h3>
              </section>
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-lightbulb"></span>
                <h3>Ugradnja LED rasvete</h3>
              </section>
              <section className="col-4 col-6-medium col-12-xsmall">
                <span className="icon solid alt major fa-key"></span>
                <h3>Mogućnost opremanja, sistem - "Ključ u ruke"</h3>
              </section>
            </div>
          </div>
          <footer className="major">
            <ul className="actions special">
              <li>
                <div
                  onClick={() => router.navigate("/contact")}
                  className="button"
                >
                  Kontaktirajte nas.
                </div>
              </li>
            </ul>
          </footer>
        </div>
      </section>
      <footer id="footer">
        <ul className="icons">
          {/* <li>
            <a href="#" className="icon brands alt fa-twitter">
              <span className="label">Twitter</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=61551257108922"
              className="icon brands alt fa-facebook-f"
            >
              <span className="label">Facebook</span>
            </a>
          </li>
          {/* <li>
            <a href="#" className="icon brands alt fa-linkedin-in">
              <span className="label">LinkedIn</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://www.instagram.com/stulic_enterijeri/"
              className="icon brands alt fa-instagram"
            >
              <span className="label">Instagram</span>
            </a>
          </li>
          {/* <li>
            <a href="#" className="icon brands alt fa-github">
              <span className="label">GitHub</span>
            </a>
          </li> */}
          <li>
            <a
              href="https://mail.google.com/"
              className="icon solid alt fa-envelope"
            >
              <span className="label">Email</span>
            </a>
          </li>
        </ul>
        <ul className="copyright">
          <li>&copy;All rights reserved.</li>
          <li>
            Design:{" "}
            <a href="https://www.linkedin.com/in/branimir-mari%C4%8Di%C4%87-16769a165/">
              {" "}
              BANE
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Home;

// <div classNameName="grid-container">
//   <div classNameName="item">
//     {images.map((image, index) => (
//       <img
//         key={index}
//         src={image}
//         alt=""
//         classNameName={index === currentImageIndex ? "active" : ""}
//       />
//     ))}
//     {texts.map((text, index) => (
//       <span
//         key={index}
//         classNameName={
//           index === currentImageIndex
//             ? "item-span black"
//             : "item-sppan black inactive"
//         }
//       >
//         <p
//           classNameName={
//             index === currentImageIndex
//               ? "paragraph-center"
//               : "paragraph-center inactive"
//           }
//         >
//           {text}
//         </p>
//       </span>
//     ))}
//   </div>

//   <br />
//   <br />
//   <div classNameName="wrap vertical">
//     <p style={{ color: "#fff" }}>Istražite našu ponudu:</p>
//     <br />
//     <div classNameName="wrap wide">
//       <div
//         classNameName="wrap vertical"
//         onClick={() => router.navigate("/galery")}
//       >
//         <img src={KitchenIcon} alt="" />
//         <span classNameName="icon-span">Kuhinje</span>
//       </div>
//       <div
//         classNameName="wrap vertical"
//         onClick={() => router.navigate("/galery")}
//       >
//         <img src={BedIcon} alt="" />
//         <span classNameName="icon-span">Spavaće sobe</span>
//       </div>
//       <div
//         classNameName="wrap vertical"
//         onClick={() => router.navigate("/galery")}
//       >
//         <img src={FurnitureIcon} alt="" />
//         <span classNameName="icon-span">Ostalo</span>
//       </div>
//     </div>
//   </div>
// </div>
