import { Outlet } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";
import "./Layout.css";

const Layout = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="layout-wrap">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
