import "./modal.css";

type ImageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  image: string;
};

const ImageModal = ({ isOpen, onClose, image }: ImageModalProps) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={image} alt="Slika namestaja" />
      </div>
    </div>
  );
};

export default ImageModal;
