import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

const BottomNavbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <nav className="bottom-navbar">
      <Link
        to="/galery/all"
        className={`bottom-nav-link ${
          currentPath.includes("all") ? "active" : ""
        }`}
      >
        Pogledaj sve
      </Link>
      <Link
        to="/galery/kitchens"
        className={`bottom-nav-link ${
          currentPath.includes("kitchens") ? "active" : ""
        }`}
      >
        Kuhinje
      </Link>
      <Link
        to="/galery/bedrooms"
        className={`bottom-nav-link ${
          currentPath.includes("bedrooms") ? "active" : ""
        }`}
      >
        Spavace sobe
      </Link>
      <Link
        to="/galery/other"
        className={`bottom-nav-link ${
          currentPath.includes("other") ? "active" : ""
        }`}
      >
        Ostalo
      </Link>
    </nav>
  );
};

export default BottomNavbar;
