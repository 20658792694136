import { useState } from "react";
import { router } from "../Routes";
import CloseIcon from "../assets/close.png";
import LogoDesktop from "../assets/logo-desktop.jpeg";
import LogoMobile from "../assets/logo-mobile.jpeg";
import MenuIcon from "../assets/menu.png";
import "./Navbar.css";

const Navbar = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Pocetna"); // Set the initial selected menu item

  const handleMenuToggle = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    switch (menuItem) {
      case "Pocetna":
        router.navigate("/"); // Handle navigation here
        break;
      case "Galerija":
        router.navigate("/galery/all"); // Handle navigation here
        break;
      case "O nama":
        router.navigate("/about"); // Handle navigation here
        break;
      case "Kontakt":
        router.navigate("/contact"); // Handle navigation here
        break;
    }
  };

  return (
    <div className="nav-wrap">
      <div className="logo-wrap">
        <img className="desktop" src={LogoDesktop} alt="Desktop Logo" />
        <img className="mobile" src={LogoMobile} alt="Mobile Logo" />
      </div>
      <div className="desktop">
        <div
          onClick={() => handleMenuItemClick("Pocetna")}
          className={`menu-item ${
            selectedMenuItem === "Pocetna" ? "active" : ""
          }`}
        >
          <span>Početna</span>
        </div>
        <div
          onClick={() => handleMenuItemClick("Galerija")}
          className={`menu-item ${
            selectedMenuItem === "Galerija" ? "active" : ""
          }`}
        >
          <span>Galerija</span>
        </div>
        <div
          onClick={() => handleMenuItemClick("O nama")}
          className={`menu-item ${
            selectedMenuItem === "O nama" ? "active" : ""
          }`}
        >
          <span>O nama</span>
        </div>
        <div
          onClick={() => handleMenuItemClick("Kontakt")}
          className={`menu-item ${
            selectedMenuItem === "Kontakt" ? "active" : ""
          }`}
        >
          <span>Kontakt</span>
        </div>
      </div>
      <div className="mobile">
        <div onClick={handleMenuToggle} className="menu-icon">
          <img src={mobileMenu ? CloseIcon : MenuIcon} alt="Menu Icon" />
        </div>
        <div className={`mobile-menu ${mobileMenu ? "open" : "closed"}`}>
          <div
            onClick={() => {
              handleMenuItemClick("Pocetna");
              handleMenuToggle();
            }}
            className={`menu-item ${
              selectedMenuItem === "Pocetna" ? "active" : ""
            }`}
          >
            <span>Početna</span>
          </div>
          <div
            onClick={() => {
              handleMenuItemClick("Galerija");
              handleMenuToggle();
            }}
            className={`menu-item ${
              selectedMenuItem === "Galerija" ? "active" : ""
            }`}
          >
            <span>Galerija</span>
          </div>
          <div
            onClick={() => {
              handleMenuItemClick("O nama");
              handleMenuToggle();
            }}
            className={`menu-item ${
              selectedMenuItem === "O nama" ? "active" : ""
            }`}
          >
            <span>O nama</span>
          </div>
          <div
            onClick={() => {
              handleMenuItemClick("Kontakt");
              handleMenuToggle();
            }}
            className={`menu-item ${
              selectedMenuItem === "Kontakt" ? "active" : ""
            }`}
          >
            <span>Kontakt</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
